<template>
  <div style="height: 100%">
    <h1 class="title">{{ actionText }}</h1>
    <div class="separator"></div>
    <el-row :gutter="20" type="flex" class="info-message">
      <el-col :span="1">
        <i class="el-icon-warning-outline"></i>
      </el-col>
      <el-col :span="23" class="linked-task-text">
        <p>
          <strong>{{ __("Task is linked to other Tasks") }}</strong>
        </p>
        <p>
          {{
            __("To preserve task dependencies, :action all linked tasks.", {
              action: actionText.toLowerCase()
            })
          }}
        </p>
      </el-col>
    </el-row>
    <div class="task-form-wrapper">
      <el-form
        ref="taskExportConfigureForm"
        class="form-container"
        label-position="top"
        label-width="100px"
        :rules="rules"
        :model="contentForm"
      >
        <el-form-item prop="process_single_task">
          <el-radio-group
            v-model="contentForm.process_single_task"
            class="radio-group"
          >
            <el-radio :label="false">{{
              __(":action all linked tasks as group", { action: actionText })
            }}</el-radio>
            <el-radio :label="true">{{
              __("Only :action this task", { action: actionText })
            }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <duplicate-group-name-input
          v-if="showDuplicateGroupNameInput"
          :groupName="contentForm.group_name"
          @input="handleGroupNameInput($event)"
        ></duplicate-group-name-input>
        <div class="button-container">
          <el-button @click="handleCancel">{{ __("Cancel") }}</el-button>
          <el-button @click="handleSubmit" type="primary">{{
            confirmButtonText
          }}</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import DuplicateGroupNameInput from "@/views/build/tasks/create-task/components/NewTaskExport/DuplicateGroupNameInput.vue";
import { validateCollectionName } from "@/api/tasks";
export default {
  name: "NewTaskExportBaseConfigure",
  components: {
    DuplicateGroupNameInput
  },
  props: {
    actionType: {
      required: true,
      type: String
    },
    taskCollectionCount: {
      required: true,
      type: Number
    }
  },
  data() {
    let groupNameValidator = async (rule, value, callback) => {
      let hasError = false;
      await validateCollectionName(value)
        .then(response => {
          if (response.data.found) {
            hasError = true;
          }
        })
        .catch(() => {
          hasError = false;
        });
      if (hasError) {
        callback(__("Group Name already exists"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        group_name: [
          {
            required: true,
            message: __("Group Name is required"),
            trigger: "blur"
          },
          { validator: groupNameValidator, trigger: "blur" }
        ]
      },
      contentForm: {
        process_single_task: false
      }
    };
  },
  computed: {
    actionText() {
      return this.actionType === "Export" ? __("Export") : __("Duplicate");
    },
    confirmButtonText() {
      if (this.contentForm.process_single_task) {
        return __(":action Task", { action: this.actionText });
      } else {
        return __(":action :collectionCount Task(s)", {
          action: this.actionText,
          collectionCount: this.taskCollectionCount
        });
      }
    },
    showDuplicateGroupNameInput() {
      return (
        this.actionType === "Duplicate" && !this.contentForm.process_single_task
      );
    }
  },
  methods: {
    handleGroupNameInput(group_name) {
      this.$set(this.contentForm, "group_name", group_name);
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleSubmit() {
      this.$refs.taskExportConfigureForm.validate(valid => {
        if (valid) {
          switch (this.actionType) {
            case "Duplicate":
              this.$emit("duplicate", this.contentForm);
              break;
          }
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
.linked-task-text {
  word-break: break-word;
}
h1 {
  font-size: 18px;
  margin-bottom: 20px;
}
.info-message {
  display: flex;
  align-items: center;
  background-color: #e9eefd;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  color: $content-theme-color;
}

.info-message i {
  font-size: 18px;
  color: #0056b3;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.info-message p {
  margin: 0;
  font-size: 14px;
  color: #0056b3;
}

.info-message p:first-child {
  font-weight: bold;
}

.separator {
  border-top: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

::v-deep .radio-group .el-radio {
  display: block;
  margin-bottom: 10px;
  color: #444444;
  word-break: break-word;
  white-space: normal;
  .el-radio__input.is-checked .el-radio__inner {
    color: #444444;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #444444;
  }
}

::v-deep .form-container .el-form-item {
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
