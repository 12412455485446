<template>
  <div>
    <h2 class="group-name-title">{{ __("Task Group Name") }}</h2>
    <div class="description">
      <p>
        {{
          __(
            "Group name will be prepended to all linked tasks upon duplication "
          )
        }}
        <em class="eg-text">{{ ".e.g " }}</em>
        <span class="shadow-text">Group Name - Task Name</span>
      </p>
    </div>
    <div class="input-block">
      <span class="input-title">{{ __("Task Group") }}</span>
      <el-form-item prop="group_name">
        <el-input
          class="input-group-name"
          :placeholder="__('Enter Group Name')"
          :value="groupName"
          @input="handleInput($event)"
        >
        </el-input>
      </el-form-item>
    </div>
  </div>
</template>
<script>
export default {
  name: "DuplicateGroupNameInput",
  props: {
    groupName: {
      type: String,
      default: ""
    }
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>
<style scoped lang="scss">
.group-name-title {
  margin: 0 0 5px 0;
}

.description {
  word-break: break-word;
  margin: 0 0 5px 0;
}

.description p {
  margin: 0;
}

.eg-text {
  font-style: italic;
  font-weight: normal;
}
.shadow-text {
  font-style: italic;
  font-weight: normal;
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
}
.input-title {
  color: #707070;
}
.input-group-name {
  margin-top: 2px;
}
</style>
